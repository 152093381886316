import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { TrackedAccordion } from '@flock/shared-ui'
import SectionLayout from '../../SharedComponents/SectionLayout'

import FlockReturnsImage from '../../../images/flock-returns.webp'
import HousingStartsImage from '../../../images/monthly-housing-starts.webp'
import BabyBoomersImage from '../../../images/baby-boomers.webp'
import PostCovidImage from '../../../images/post-covid.webp'
import ProjectedReturnsImage from '../../../images/projected-returns.webp'
import GeoExposureImage from '../../../images/geo-exposure.webp'
import ValuationsToolImage from '../../../images/valuations-tool.webp'
import BeforeAfterImage from '../../../images/before-after.webp'
import { InvestMissionSectionPresentationalProps } from './investMissionSectionTypes'

const InvestMissionSectionDesktop = (
  props: InvestMissionSectionPresentationalProps
) => {
  const { offerQuestions } = props

  return (
    <SectionLayout
      name="invest-story"
      backgroundColor="trustBlue.main"
      verticalPadding="0px"
    >
      {/* Section 1 */}
      <Grid item xs={12}>
        <Typography variant="h2" color="gray8.main">
          Why do enduring growth drivers support owning single-family rentals?
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="cta" color="gray8.main">
            Housing Shortage
          </Typography>
          <Typography variant="p2" color="gray8.main">
            After the sharp crash in building post the Great Financial Crisis,
            monthly new housing starts are still below where they were in 2000,
            and the US is 4 million units short of housing demand. The rise of
            NIMBY* policies have also suppressed construction of new housing.
          </Typography>
          <Typography variant="p2" color="gray8.main">
            People who own homes are trapped with golden handcuffs, exasperating
            the shortage--80% of homeowners have sub-5% mortgage and 25% sub-3%
            fixed rate mortgages, making it cost-prohibitive to trade up to
            another house.
          </Typography>
          <Typography variant="p4" color="gray8.main">
            *NIMBY, an acronym for &quot;Not In My Back Yard,&quot; refers to
            the opposition by residents to a proposed development in their local
            area.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box component="img" src={HousingStartsImage} width="100%" />
      </Grid>
      <Grid item xs={6}>
        <Box component="img" src={BabyBoomersImage} width="100%" />
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="cta" color="gray8.main">
            Demographics
          </Typography>
          <Typography variant="p2" color="gray8.main">
            Demographic shifts such as more single-person households,
            millennials delaying homeownership, and an aging population
            preferring rentals, sustain demand for single-family rentals.
            Millennials are entering peak household formation years, tapping
            into a growing rental market affected by these trends. As Baby
            Boomers start to retire, many may downsize from larger homes to more
            manageable properties, with some opting to rent to avoid
            homeownership responsibilities in later years.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="cta" color="gray8.main">
            Post-COVID Shift
          </Typography>
          <Typography variant="p2" color="gray8.main">
            The pandemic has accelerated the trend of people moving away from
            densely populated urban areas to more suburban locations for a more
            flexible living arrangement.
          </Typography>
          <Typography variant="p2" color="gray8.main">
            Single-family homes in suburban areas have become more desirable due
            to the increased demand for space, privacy, and access to outdoor
            amenities, which has driven up their value.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box component="img" src={PostCovidImage} width="100%" />
      </Grid>
      <Grid item xs={6}>
        <Box component="img" src={ProjectedReturnsImage} width="100%" />
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="cta" color="gray8.main">
            Rent vs Sell Calculus
          </Typography>
          <Typography variant="p2" color="gray8.main">
            Choosing between renting or selling a single-family home balances
            long-term income against immediate capital. Renting offers steady
            cash flow and tax benefits but requires active management and
            reduces liquidity. Selling provides quick funds and eliminates
            management duties but may incur capital gains tax and depreciation
            recapture.
          </Typography>
          <Typography variant="p2" color="gray8.main">
            The 721 exchange is strategic for exiting direct property ownership,
            allowing investors to stay invested in real estate, defer capital
            gains taxes, and reduce management burdens while increasing
            liquidity and diversification.
          </Typography>
        </Box>
      </Grid>

      {/* Section 2 */}
      <Grid item xs={12} mt="32px">
        <Typography variant="h2" color="gray8.main">
          How does Flock select markets?
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="cta" color="gray8.main">
            Market Potential & Diversification
          </Typography>
          <Typography variant="p2" color="gray8.main">
            Flock targets high-potential markets, focusing on favorable
            risk-return profiles, attractive yields, and home price
            appreciation. We evaluate these markets by analyzing the economic
            environment, industry presence, regulatory conditions, and
            population growth fueled by job opportunities and quality of life.
            This strategy ensures steady housing demand.
          </Typography>
          <Typography variant="p2" color="gray8.main">
            Flock&apos;s diversified portfolio offers exposure to single family
            rentals across multiple markets, with an array of high yield as well
            as high appreciation assets in desirable markets across the country.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box component="img" src={GeoExposureImage} width="100%" />
      </Grid>
      <Grid item xs={6}>
        <Box component="img" src={ValuationsToolImage} width="100%" />
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="cta" color="gray8.main">
            Data-driven Underwriting
          </Typography>
          <Typography variant="p2" color="gray8.main">
            Flock uses a variety of data points to underwrite offers for assets.
            Our valuations consider data like rental and sale comps, market
            stability, and our understanding of assumptions to ensure a fair and
            accurate valuation for every asset.
          </Typography>
          <Typography variant="p2" color="gray8.main">
            We execute inspections on all assets in order to provide a final
            offer, document asset condition and determine remodel costs
            necessary to bring assets up to Flock Standards.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="cta" color="gray8.main">
            Professional Asset Management
          </Typography>
          <Typography variant="p2" color="gray8.main">
            Flock&apos;s team, with decades of experience at top global real
            estate investment institutions, aims to enhance the lives of real
            estate owners. Our seasoned Asset Management Team applies their
            extensive expertise to meticulously manage each asset, ensuring
            value and peace of mind for tenants and clients alike. We also use
            our market knowledge to strategically identify and seize
            opportunities within new markets, aligning with our operational
            capacity.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box component="img" src={BeforeAfterImage} width="100%" />
      </Grid>

      {/* Section 3 */}
      <Grid item xs={12} mt="32px">
        <Typography variant="h2" color="gray8.main">
          Why Flock?
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="cta" color="gray8.main">
            About the Flock Fund
          </Typography>
          <Typography variant="p2" color="gray8.main">
            We empower landlords to exit their rentals with the 721 exchange.
            Exchange your rentals for ownership in a professionally managed real
            estate fund, designed specifically for retiring landlords.
          </Typography>
          <Typography variant="p2" color="gray8.main">
            Flock offers a low-cost, low-leverage, diversified portfolio of
            single-family rentals that closely tracks the US housing market.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box component="img" src={FlockReturnsImage} width="100%" />
      </Grid>

      {/* FAQ Section */}
      <Grid item xs={12} mt="32px">
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="cta" color="gray8.main">
            Frequently Asked Questions
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" gap="16px">
          {offerQuestions.map((offerQuestion: { title: any; text: any }) => {
            const { title, text } = offerQuestion
            return (
              <TrackedAccordion
                summary={title}
                disableGutters
                styleVariant="filled"
                collapsible={false}
                sx={{
                  '& .MuiAccordionSummary-root': {
                    background: `var(--white5, rgba(255, 255, 255, 0.50))`,
                    borderTopLeftRadius: '16px',
                    borderTopRightRadius: '16px',
                    borderBottomLeftRadius: '16px',
                    borderBottomRightRadius: '16px',
                  },
                  '& .Mui-expanded': {
                    borderBottomLeftRadius: '0px!important',
                    borderBottomRightRadius: '0px!important',
                  },
                  '& .MuiAccordionDetails-root': {
                    padding: '0px 16px 16px 16px',
                    background: `var(--white5, rgba(255, 255, 255, 0.50))`,
                    borderBottomLeftRadius: '16px',
                    borderBottomRightRadius: '16px',
                  },
                  '& .MuiTypography-h4': {
                    fontSize: '16px!important',
                  },
                }}
              >
                <Box>
                  <Typography variant="p3" sx={{ fontSize: '14px!important' }}>
                    {text}
                  </Typography>
                </Box>
              </TrackedAccordion>
            )
          })}
        </Box>
      </Grid>
    </SectionLayout>
  )
}

export default InvestMissionSectionDesktop
